import { useState } from "react";

import { Dropdown, Flex, Modal, Popconfirm, Select, Typography } from "antd";

import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { crawlingJobTableColumns } from "../Table/columns";
import { useCrawlingJobs } from "../../hooks/useCrawlingJobs";
import { Button } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";

import "./index.css";
function CrawlingManagement() {
  const {
    crawlingJobs,
    crawlingJobsLoading,
    handleTriggerApiCrawling,
    handleTriggerHtmlCrawling,
    handleTriggerOpenAICrawling,
    handleRefresh,
  } = useCrawlingJobs();

  const [universities, setUniversities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await handleTriggerOpenAICrawling(universities);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items = [
    {
      key: "1",
      label: (
        <Popconfirm
          title="Trigger the job"
          description="This action will take up to 7 days to finish. Do you want to continue?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleTriggerApiCrawling}
        >
          <p>QS-api</p>,
        </Popconfirm>
      ),
    },
    {
      key: "2",
      label: (
        <Popconfirm
          title="Trigger the job"
          description="This action will take up to 7 days to finish. Do you want to continue?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleTriggerHtmlCrawling}
        >
          <p>Program details-html</p>
        </Popconfirm>
      ),
    },
    {
      key: "3",
      label: <p onClick={showModal}>Programs for universities-OpenAI</p>,
    },
  ];

  return (
    <ContentWrapper>
      <Flex justify="space-between">
        <Typography.Title className="content__header" level={3}>
          Crawling job
        </Typography.Title>
      </Flex>

      <Flex justify="space-between">
        <Dropdown menu={{ items }} placement="bottomLeft" arrow>
          <Button>Trigger Crawling Job</Button>
        </Dropdown>
        <Button onClick={handleRefresh} type={BUTTON_TYPE.PRIMARY}>
          Refresh
        </Button>
      </Flex>

      <CustomTable
        loading={crawlingJobsLoading}
        dataSource={crawlingJobs}
        columns={crawlingJobTableColumns()}
      />
      <Modal
        title="Universities"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          className="universities__select"
          value={universities}
          onChange={(value) => {
            setUniversities(value);
          }}
          mode="tags"
          placeholder="Input your university"
        />
      </Modal>
    </ContentWrapper>
  );
}

export default CrawlingManagement;
