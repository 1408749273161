import { Flex, Form, Input, Modal, Select, Spin, Tabs, Typography } from "antd";
import moment from "moment";

import { infoIcon } from "../../../../../assets/icons";
import { Button, Icon } from "../../../../../common/components";
import { BUTTON_TYPE } from "../../../../../common/components/Button";
import SessionTimeInfo from "../../../../../common/components/SessionTimeInfo";
import { BOOKED_SESSION_ACTIONS } from "../../../../../common/constants/actions";
import DatePicker from "../../TimeSlotManagement/DatePicker";
import TimePicker from "../../TimeSlotManagement/TimePicker";
import Header from "../Card/Header";

function BookedSessionModal({
  studentDetailLoading,
  form,
  open,
  action,
  majors,
  onSubmit,
  onClose,
  onTabChange,
}) {
  const renderContent = () => {
    if (action === BOOKED_SESSION_ACTIONS.EDIT_MAJOR) {
      const majorsOptions = majors.map((x) => ({
        label: x.major,
        value: x.major,
      }));

      return (
        <Form form={form} onFinish={(values) => onSubmit(action, values)}>
          <Flex vertical gap={10}>
            <Flex gap={10}>
              <Icon width={15} src={infoIcon} />
              <Typography.Text strong>
                {" "}
                Select maximum 20 items{" "}
              </Typography.Text>
            </Flex>
            <Form.Item name="studentMajors">
              <Select
                mode="multiple"
                maxCount={20}
                placeholder="Start typing to search major by"
                showSearch
                optionFilterProp="label"
                notFoundContent={"Major not found"}
                options={majorsOptions}
              />
            </Form.Item>
          </Flex>
        </Form>
      );
    }

    if (action === BOOKED_SESSION_ACTIONS.EDIT_NOTE)
      return (
        <Form form={form} onFinish={(values) => onSubmit(action, values)}>
          <Flex vertical gap={15}>
            <Header advisorName={form.getFieldValue("advisorName")} />

            <Flex vertical gap={5}>
              <Typography.Text strong>Student</Typography.Text>
              <Input value={form.getFieldValue("studentName")} disabled />
            </Flex>

            <Flex vertical gap={5}>
              <SessionTimeInfo />
              <Flex>
                <DatePicker
                  value={moment(form.getFieldValue("startDate"))}
                  disabled
                />
                <TimePicker
                  value={[
                    moment(form.getFieldValue("startDate")),
                    moment(form.getFieldValue("endDate")),
                  ]}
                  disabled
                />
              </Flex>
            </Flex>
            <Flex vertical gap={5}>
              <Typography.Text strong>Meeting link</Typography.Text>
              <Form.Item>
                <Input value={form.getFieldValue("meetingLink")} disabled />
              </Form.Item>
            </Flex>

            <Flex vertical gap={5}>
              <Typography.Text strong>Meeting notes</Typography.Text>
              <Form.Item name="notes">
                <Input.TextArea rows={5} />
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      );
    if (action === BOOKED_SESSION_ACTIONS.VIEW_INFO) {
      return studentDetailLoading ? (
        <div style={{ height: 50, position: "relative" }}>
          <Spin className="spinner" />
        </div>
      ) : (
        <Form form={form} onFinish={(values) => onSubmit(action, values)}>
          <Form.Item label="Title" name="title">
            <Input variant="borderless" />
          </Form.Item>
          <Form.Item label="Majors" name="studentMajors">
            <Select
              open={false}
              suffixIcon={null}
              mode="multiple"
              variant="borderless"
            />
          </Form.Item>
          <Form.Item label="Orientation" name="orientation">
            <Input variant="borderless" />
          </Form.Item>
          <Form.Item label="Qualities" name="qualities">
            <Select
              open={false}
              suffixIcon={null}
              mode="multiple"
              variant="borderless"
            />
          </Form.Item>
          <Form.Item label="Summary" name="summary">
            <Input.TextArea rows={5} variant="borderless" />
          </Form.Item>
          <Form.Item label="Traits" name="traits">
            <Select
              open={false}
              suffixIcon={null}
              mode="multiple"
              variant="borderless"
            />
          </Form.Item>
        </Form>
      );
    }
  };

  const items = [
    {
      key: BOOKED_SESSION_ACTIONS.VIEW_INFO,
      label: BOOKED_SESSION_ACTIONS.VIEW_INFO,
      children: renderContent(),
    },
    {
      key: BOOKED_SESSION_ACTIONS.EDIT_MAJOR,
      label: BOOKED_SESSION_ACTIONS.EDIT_MAJOR,
      children: renderContent(),
    },
    {
      key: BOOKED_SESSION_ACTIONS.EDIT_NOTE,
      label: BOOKED_SESSION_ACTIONS.EDIT_NOTE,
      children: renderContent(),
    },
  ];
  return (
    <Modal
      title={action}
      open={open}
      closable
      onCancel={onClose}
      footer={
        <Flex gap={10}>
          <Button onClick={onClose} type={BUTTON_TYPE.DEFAULT}>
            Cancel
          </Button>
          {action !== BOOKED_SESSION_ACTIONS.VIEW_INFO && (
            <Button
              onClick={form.submit}
              htmlType="submit"
              type={BUTTON_TYPE.PRIMARY}
            >
              Save
            </Button>
          )}
        </Flex>
      }
    >
      <Tabs activeKey={action} items={items} onChange={onTabChange} />
    </Modal>
  );
}

export default BookedSessionModal;
