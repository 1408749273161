import { useEffect, useState } from "react";

import { debounce } from "lodash";
import { companyService } from "../../../services";
import { companiesTableDataSourceMapper } from "../common/mapper";

export const useCompanies = () => {
  //**** STATE ***//
  const [companies, setCompanies] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  const handleOnSearch = (event) => {
    const value = event.target.value;
    setKeyword(value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchCompanies(current);
  };

  const handleSelectCompany = (company) => {
    setEditingCompany(company);
  };
  const fetchCompanies = debounce(async (currentPage) => {
    try {
      setCompanyLoading(true);
      const response = await companyService.getCompanies(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setCompanies(companiesTableDataSourceMapper(response.data.companies));
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setCompanyLoading(false);
    }
  }, 500);
  const handleCreateCompany = async (values) => {
    const response = await companyService.createCompany({
      ...values,
    });
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  const handleEditCompany = async (values) => {
    const response = await companyService.editCompany(values);
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  useEffect(() => {
    fetchCompanies(pagination.current);

    return () => {
      fetchCompanies.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    editingCompany,
    companies,
    companyLoading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleCreateCompany,
    handleEditCompany,
    handleSelectCompany,
  };
};
