import { createContext, useContext, useEffect, useState } from "react";

import { notification } from "antd";
import { advisorService, authService } from "../../services";

const UserContext = createContext();
export const USER_TYPE = {
  ADMIN: "admin",
  ADVISOR: "advisor",
};
function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleLogin = async (email, password) => {
    const response = await authService.login(email, password);
    if (response) {
      setUser(response.data);
      setIsAuthenticated(true);
      localStorage.setItem("token", response.data.token);
    }
  };

  // Function to log out a user
  const handleLogout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem("token");
  };
  const loadUser = async () => {
    try {
      if (!localStorage.getItem("token")) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const response = await authService.loadUser();
      if (response) {
        setUser(response.data);
        setIsAuthenticated(true);
      }
    } catch (error) {
      localStorage.removeItem("token");
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangeAvatar = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        notification["error"]({
          message: "Action failed!",
          description: "File size must be less than 5MB",
        });
        return;
      }
    }

    try {
      await advisorService.updateAvatar(file);
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
      setNeedRefresh((prev) => !prev);
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  const handleChangePassword = async (newPassword) => {
    await authService
      .authServiceFactory(user.userType)
      .changePassword(newPassword);
  };

  useEffect(() => {
    loadUser();
  }, [needRefresh]);
  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        handleLogin,
        handleLogout,
        handleChangeAvatar,
        handleChangePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export default UserProvider;

export const useUser = () => {
  return useContext(UserContext);
};
