import { bookIcon, userIcon, calendarIcon } from "../../../../assets/icons";
import { Icon } from "../../../../common/components";
import { USER_TYPE } from "../../../../contexts/user";

export const MENU_KEY = {
  USER: "User",
  STUDENTS: "Students",
  ADVISORS: "Advisors",
  COMPANIES: "Companies",
  JOB_TRIALS: "Job Trials",
  JOB_APPLICATION: "Job Application",
  RETAKE: "Retake Requests",
  APPOINTMENTS: "Appointment",
  TIME_SLOTS: "Available Time Slots",
  BOOKED_SESSIONS: "Booked Sessions",
  PROGRAMS: "Programs",
  UNIVERSITIES: "Universities",
  CRAWLING_JOBS: "Crawling Jobs",
  PRODUCTS: "Products",
};

export const menuItems = (userType) =>
  userType === USER_TYPE.ADMIN ? adminMenuItems : advisorMenuItems;

const adminMenuItems = [
  {
    key: MENU_KEY.USER,
    label: MENU_KEY.USER,
    icon: <Icon height={45} width={40} src={userIcon} />,
    children: [
      {
        key: MENU_KEY.STUDENTS,
        label: MENU_KEY.STUDENTS,
      },
      {
        key: MENU_KEY.ADVISORS,
        label: MENU_KEY.ADVISORS,
      },
    ],
  },
  {
    key: MENU_KEY.COMPANIES,
    label: MENU_KEY.COMPANIES,
  },
  {
    key: MENU_KEY.JOB_TRIALS,
    label: MENU_KEY.JOB_TRIALS,
  },
  {
    key: MENU_KEY.RETAKE,
    label: MENU_KEY.RETAKE,
    icon: <Icon height={40} width={40} src={bookIcon} />,
  },
  {
    key: MENU_KEY.APPOINTMENTS,
    label: MENU_KEY.APPOINTMENTS,
    icon: <Icon height={45} width={40} src={calendarIcon} />,
    children: [
      {
        key: MENU_KEY.BOOKED_SESSIONS,
        label: MENU_KEY.BOOKED_SESSIONS,
      },
      {
        key: MENU_KEY.JOB_APPLICATION,
        label: MENU_KEY.JOB_APPLICATION,
      },
    ],
  },
  {
    key: MENU_KEY.UNIVERSITIES,
    label: MENU_KEY.UNIVERSITIES,
  },
  {
    key: MENU_KEY.PROGRAMS,
    label: MENU_KEY.PROGRAMS,
  },
  {
    key: MENU_KEY.CRAWLING_JOBS,
    label: MENU_KEY.CRAWLING_JOBS,
  },
  {
    key: MENU_KEY.PRODUCTS,
    label: MENU_KEY.PRODUCTS,
  },
];

const advisorMenuItems = [
  {
    key: MENU_KEY.APPOINTMENTS,
    label: MENU_KEY.APPOINTMENTS,
    icon: <Icon height={45} width={40} src={calendarIcon} />,
    children: [
      {
        key: MENU_KEY.TIME_SLOTS,
        label: MENU_KEY.TIME_SLOTS,
      },
      {
        key: MENU_KEY.BOOKED_SESSIONS,
        label: MENU_KEY.BOOKED_SESSIONS,
      },
    ],
  },
];
