import { Image, Typography } from "antd";

import { NULL_VALUE } from "..";
import { editIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { COMPANY_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const companyTableColumns = (onOpenModal) => [
  {
    title: "Logo",
    key: "logo",
    dataIndex: "profileImage",
    render: (_, { profileImage }) => {
      return (
        profileImage && <Image width={70} height={70} src={profileImage} />
      );
    },
  },
  {
    title: "Name",
    key: "englishName",
    dataIndex: "englishName",
    render: (_, { englishName }) => {
      return <Typography.Text>{englishName || NULL_VALUE} </Typography.Text>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (_, { email }) => {
      return <Typography.Text>{email || NULL_VALUE} </Typography.Text>;
    },
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },

  {
    title: "Action",
    key: "action",
    render: (_, company) => {
      return (
        <Icon
          className="logo__action"
          preview={false}
          src={editIcon}
          onClick={() => onOpenModal(company, COMPANY_TABLE_ACTIONS.EDIT_INFO)}
        />
      );
    },
  },
];
