import { useState } from "react";

import { Flex, Form, Typography } from "antd";

import ContentWrapper from "../../components/ContentWrapper";
import SearchBar from "../../../../common/components/SearchBar";
import CustomTable from "../Table";
import EditStudentModal from "./EditStudentModal";
import { studentTableColumns } from "../Table/columns";
import { useStudents } from "../../hooks/useStudents";

import "./index.css";
import { STUDENT_TABLE_ACTIONS } from "../../../../common/constants/actions";

const { Title } = Typography;
function UserManagement() {
  const {
    students,
    studentLoading,
    pagination,
    keyword,
    handleOnSearch,
    handleDeactivateStudent,
    handlePagination,
    handleEditUser,
  } = useStudents();

  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });
  const [editingStudent, setEditingStudent] = useState(null);

  const handleOkEditUser = async (studentInfo) => {
    await handleEditUser({ studentID: editingStudent.key, ...studentInfo });
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false });
  };

  const handleOpenEditModal = (student) => {
    setEditingStudent(student);
    form.setFieldsValue(student);
  };

  const handleOpenModal = (action, student) => {
    setModal({ isOpen: true, action });
    switch (action) {
      case STUDENT_TABLE_ACTIONS.EDIT_INFO:
        handleOpenEditModal(student);
        break;
      default:
        break;
    }
  };

  const handleDeactivateConfirm = (studentID) => {
    handleDeactivateStudent(studentID);
  };
  const renderStudentTableModal = () => {
    switch (modal.action) {
      case STUDENT_TABLE_ACTIONS.EDIT_INFO:
        return (
          <EditStudentModal
            action={modal.action}
            open={modal.isOpen}
            form={form}
            initialValues={editingStudent}
            onFinish={handleOkEditUser}
            onCancel={handleCloseModal}
          />
        );

      default:
        break;
    }
  };
  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Title className="content__header" level={3}>
            User management
          </Title>
        </Flex>

        <SearchBar
          placeholder="Search by phone number/ name"
          value={keyword}
          onSearch={handleOnSearch}
        />
        <CustomTable
          loading={studentLoading}
          dataSource={students}
          pagination={pagination}
          columns={studentTableColumns(
            handleDeactivateConfirm,
            handleOpenModal
          )}
          onPagination={handlePagination}
        />
      </ContentWrapper>
      {renderStudentTableModal()}
    </>
  );
}

export default UserManagement;
