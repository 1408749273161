import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import ProtectedRoute from "./ProtectedRoute";

import { createBrowserRouter } from "react-router-dom";
import CountryCodesProvider from "../contexts/countryCodes";
import { MajorsProvider } from "../contexts/majors";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <CountryCodesProvider>
          <MajorsProvider>
            <Dashboard />
          </MajorsProvider>
        </CountryCodesProvider>
      </ProtectedRoute>
    ),
  },
]);
