import {
  Dropdown as DropdownAntd,
  Form,
  Input,
  Modal,
  notification,
} from "antd";

import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import { USER_TYPE, useUser } from "../../../../contexts/user";
import UserInfo from "../UserInfo";

function Dropdown() {
  /** STATE */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { user, handleLogout, handleChangeAvatar, handleChangePassword } =
    useUser();
  const inputFileRef = useRef(null);

  /** HANDLER */
  const handleClickLogoutBtn = () => {
    handleLogout();
    navigate("/");
  };
  const handleOpenSelectFile = () => {
    inputFileRef.current?.click();
  };
  const handleOpenPasswordChangingModal = () => {
    setIsModalOpen(true);
  };

  const handleClosePasswordChangingModal = () => {
    setIsModalOpen(false);
  };
  const onSubmit = async ({ newPassword }) => {
    try {
      await handleChangePassword(newPassword);
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
      handleClosePasswordChangingModal();
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  const items = [
    user.userType === USER_TYPE.ADVISOR && {
      key: "1",
      label: (
        <>
          <Button
            className="dropdown_btn"
            type={BUTTON_TYPE.TEXT}
            onClick={handleOpenSelectFile}
          >
            Change avatar
          </Button>
          <input
            style={{ display: "none" }}
            ref={inputFileRef}
            type="file"
            accept="image/*"
            onChange={handleChangeAvatar}
            placeholder="Change avatar"
          />
        </>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="dropdown_btn"
          type={BUTTON_TYPE.TEXT}
          onClick={handleOpenPasswordChangingModal}
        >
          Change password
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          className="dropdown_btn"
          type={BUTTON_TYPE.TEXT}
          onClick={handleClickLogoutBtn}
        >
          {" "}
          Log out{" "}
        </Button>
      ),
    },
  ];
  return (
    <>
      <DropdownAntd
        menu={{
          items,
        }}
      >
        <UserInfo
          userType={user.userType}
          profileImage={user.profileImage}
          userName={user.firstName || user.englishName}
        />
      </DropdownAntd>
      <Modal
        title="Password changing"
        open={isModalOpen}
        onCancel={handleClosePasswordChangingModal}
        footer={
          <Button type="primary" htmlType="submit" onClick={form.submit}>
            Submit
          </Button>
        }
      >
        <Form form={form} onFinish={onSubmit}>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input new password",
              },
            ]}
          >
            <Input.Password placeholder="input your new password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Dropdown;
