import axiosClient from "../base";

export const getAdvisors = (keyword, page = 1, limit = 10) => {
  return axiosClient.get("advisors", {
    params: {
      keyword,
      page,
      limit,
    },
  });
};

export const createAdvisor = (
  email,
  englishName,
  englishBio,
  arabicName,
  arabicBio,
  sessionQuota
) => {
  return axiosClient.post("advisors", {
    email,
    englishName,
    englishBio,
    arabicName,
    arabicBio,
    sessionQuota,
  });
};
export const editAdvisor = (
  advisorID,
  email,
  englishName,
  englishBio,
  arabicName,
  arabicBio,
  sessionQuota
) => {
  return axiosClient.put(`advisors/${advisorID}`, {
    email,
    englishName,
    englishBio,
    arabicName,
    arabicBio,
    sessionQuota,
  });
};

const createAvatarUploadFormData = (value) => {
  const formData = new FormData();
  formData.append("profileImage", value);
  return formData;
};

export const updateAvatar = (value) => {
  return axiosClient.post(
    `advisors/uploadProfileImage`,
    createAvatarUploadFormData(value),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
