import { useState, useEffect } from "react";

import { studentService } from "../../../services";
import { debounce } from "lodash";
import { notification } from "antd";
import { studentTableDataSourceMapper } from "../common/mapper";

export const useStudents = () => {
  //**** STATE ***//
  const [students, setStudents] = useState([]);
  const [studentLoading, setStudentLoading] = useState(true);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  //**** HANDLER ***//
  const handleOnSearch = (event) => {
    setKeyword(event.target.value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleDeactivateStudent = async (studentID) => {
    try {
      const response = await studentService.deactivateStudent(studentID);
      if (response) {
        setNeedRefresh((prev) => !prev);
        notification["success"]({
          message: "Update success!",
          description: "Deactivating student successfully",
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Update failed!",
        description: "Issue while deactivating student",
      });
    }
  };
  const handlePagination = async ({ current }) => {
    await fetchStudents(current);
  };

  const fetchStudents = debounce(async (currentPage) => {
    try {
      setStudentLoading(true);
      const response = await studentService.getStudents(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setStudents(studentTableDataSourceMapper(response.data.students));
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setStudentLoading(false);
    }
  }, 500);
  const handleEditUser = async (studentInfo) => {
    try {
      const response = await studentService.editStudent(studentInfo);
      if (response) {
        notification["success"]({
          message: "Update success!",
          description: "Update student successfully",
        });
        setNeedRefresh((prev) => !prev);
      }
    } catch (error) {
      notification["error"]({
        message: "Update failed!",
        description: "Issue while updating student info",
      });
    }
  };
  useEffect(() => {
    fetchStudents(pagination.current);

    return () => {
      fetchStudents.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    students,
    studentLoading,
    pagination,
    keyword,
    handleOnSearch,
    handleDeactivateStudent,
    handlePagination,
    handleEditUser,
  };
};
