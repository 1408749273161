import { useState } from "react";

import { Checkbox, Flex, Form, notification, Typography } from "antd";

import { plusIcon } from "../../../../assets/icons";
import { Button, Icon } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import SearchBar from "../../../../common/components/SearchBar";
import { PROGRAM_TABLE_ACTIONS } from "../../../../common/constants/actions";
import { checkHasChanges } from "../../../../common/utils/common";
import { usePrograms } from "../../hooks/usePrograms";
import ContentWrapper from "../ContentWrapper";
import CustomTable from "../Table";
import { programTableColumns } from "../Table/columns/program";
import ProgramModal from "./Modal";
import { useMajors } from "../../../../contexts/majors";

function ProgramManagement() {
  /** STATE */
  const {
    universitiesOptions,
    editingProgram,
    programs,
    programLoading,
    keyword,
    pagination,
    showManuallyAdded,
    handlePagination,
    handleOnSearch,
    handleCreateProgram,
    handleEditProgram,
    handleDeleteProgram,
    handleSelectProgram,
    handleManuallyAddedChange,
    handleOptionOnSearch,
  } = usePrograms();

  const { majors } = useMajors();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });

  const getFieldsToCompare = () => {
    const fieldsToCompare = { ...editingProgram };

    return fieldsToCompare;
  };
  /** HANDLER */
  const handleOpenProgramModal = (program, action) => {
    setModal({ isOpen: true, action });
    handleSelectProgram(program);
    switch (action) {
      case PROGRAM_TABLE_ACTIONS.CREATE_PROGRAM:
        break;
      case PROGRAM_TABLE_ACTIONS.EDIT_PROGRAM:
        form.setFieldsValue(program);
        break;

      default:
        break;
    }
  };
  const handleCloseModal = () => {
    setModal({ isOpen: false });
    form.resetFields();
  };

  const handleOnSubmit = async (action, newValues) => {
    try {
      switch (modal.action) {
        case PROGRAM_TABLE_ACTIONS.CREATE_PROGRAM:
          await handleCreateProgram(newValues);
          break;

        case PROGRAM_TABLE_ACTIONS.EDIT_PROGRAM:
          if (!checkHasChanges(getFieldsToCompare(), newValues)) return;
          await handleEditProgram({
            id: editingProgram.id,
            ...newValues,
          });
          break;

        default:
          break;
      }
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
      handleCloseModal();
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };

  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Typography.Title className="content__header" level={3}>
            Program
          </Typography.Title>
          {showManuallyAdded && (
            <Button
              type={BUTTON_TYPE.PRIMARY}
              icon={<Icon width={16} height={16} src={plusIcon} />}
              onClick={() =>
                handleOpenProgramModal(
                  null,
                  PROGRAM_TABLE_ACTIONS.CREATE_PROGRAM
                )
              }
            >
              Program
            </Button>
          )}
        </Flex>
        <Flex justify="space-between" align="center">
          <SearchBar
            placeholder="Search by program name, university"
            value={keyword}
            onSearch={handleOnSearch}
          />
          <Checkbox
            value={showManuallyAdded}
            onChange={handleManuallyAddedChange}
          >
            Manually Added
          </Checkbox>
        </Flex>

        <CustomTable
          loading={programLoading}
          dataSource={programs}
          pagination={pagination}
          columns={programTableColumns(
            handleOpenProgramModal,
            handleDeleteProgram
          )}
          onPagination={handlePagination}
        />
      </ContentWrapper>

      <ProgramModal
        action={modal.action}
        form={form}
        majors={majors}
        open={modal.isOpen}
        universities={universitiesOptions}
        onProgramOptionSearch={handleOptionOnSearch}
        onCancel={handleCloseModal}
        onFinish={handleOnSubmit}
      />
    </>
  );
}

export default ProgramManagement;
