import { Flex } from "antd";
import { Navigate, useNavigate } from "react-router-dom";

import AuthForm from "./components/Form";
import { Image, notification } from "antd";
import { useUser } from "../../contexts/user";
import { logo } from "../../assets/icons";
import "./index.css";

function Login() {
  const { handleLogin, isAuthenticated, isLoading } = useUser();
  const navigate = useNavigate();
  const handleOnFinish = async (values) => {
    try {
      const { email, password } = values;
      await handleLogin(email, password);
      navigate("/dashboard");
    } catch (error) {
      notification["error"]({
        message: "Login failed!",
        description: "Your email or password is invalid",
      });
    }
  };

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    !isLoading && (
      <Flex
        className="login__container"
        justify="center"
        align="center"
        vertical
        gap={20}
      >
        <Image width={150} preview={false} src={logo} />
        <AuthForm onFinish={handleOnFinish} />
      </Flex>
    )
  );
}

export default Login;
