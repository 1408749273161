import { Avatar, Flex, Space, Typography } from "antd";

import "./index.css";
const { Text } = Typography;
function UserInfo({ userType, profileImage, userName, ...props }) {
  const firstCharacter = userName.substring(0, 1).toUpperCase();
  return (
    <Space
      {...props}
      className="user__info__container"
      size={"small"}
      align="center"
    >
      <Avatar className="user__avatar" src={profileImage}>
        {firstCharacter}
      </Avatar>
      <Flex vertical>
        {userName && (
          <Text className="user__info__name" strong>
            {userName}
          </Text>
        )}
        {userType && <Text className="user__info__role">{userType}</Text>}
      </Flex>
    </Space>
  );
}

export default UserInfo;
