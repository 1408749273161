import { Form, Input, Modal, Select } from "antd";

import Button, { BUTTON_TYPE } from "../../../../../common/components/Button";

function UniversityModal({ action, open, form, onCancel, onFinish }) {
  return (
    <Modal title={action} open={open} footer={null} onCancel={onCancel}>
      <Form form={form} onFinish={(value) => onFinish(value, action)}>
        <Form.Item
          name="university_name"
          label="University name"
          rules={[
            {
              required: true,
              message: "Please input university's name!",
            },
          ]}
        >
          <Input placeholder="input university's name" />
        </Form.Item>

        <Form.Item name="campus_names" label="Campus Names">
          <Select
            notFoundContent={null}
            placeholder="input campus names"
            mode="tags"
          />
        </Form.Item>

        <Form.Item name="uni_website" label="University website">
          <Input placeholder="input university's website" />
        </Form.Item>

        <Form.Item name="uni_email" label="University email">
          <Input placeholder="input university's email" />
        </Form.Item>

        <Form.Item name="regions" label="Regions">
          <Select
            notFoundContent={null}
            placeholder="input regions"
            mode="tags"
          />
        </Form.Item>

        <Form.Item name="countries" label="Countries">
          <Select
            notFoundContent={null}
            placeholder="input countries"
            mode="tags"
          />
        </Form.Item>

        <Form.Item name="cities" label="Cities">
          <Select
            notFoundContent={null}
            placeholder="input cities"
            mode="tags"
          />
        </Form.Item>

        <Form.Item name="rankings_position" label="Rankings position">
          <Input placeholder="input university's ranking position" />
        </Form.Item>

        <Form.Item>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UniversityModal;
