import { USER_TYPE } from "../../contexts/user";
import axiosClient from "../base";

export const login = (email, password) => {
  return axiosClient.post("portal/login", {
    email,
    password,
  });
};

export const loadUser = () => {
  return axiosClient.get("portal/info");
};

export const authServiceFactory = (userType) => {
  if (userType === USER_TYPE.ADMIN)
    return {
      changePassword: changePasswordForAdmin,
    };
  if (userType === USER_TYPE.ADVISOR)
    return {
      changePassword: changePasswordForAdvisor,
    };
};

export const changePasswordForAdmin = (newPassword) => {
  return axiosClient.post("admin/changePassword", {
    newPassword,
  });
};

export const changePasswordForAdvisor = (newPassword) => {
  return axiosClient.post("advisors/changePassword", {
    newPassword,
  });
};
