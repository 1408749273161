import { useEffect, useState } from "react";

import { debounce } from "lodash";
import { jobTrialService } from "../../../services";

export const useJobTrials = () => {
  //**** STATE ***//
  const [jobTrials, setJobTrials] = useState([]);
  const [editingJobTrial, setEditingJobTrial] = useState(null);
  const [jobTrialLoading, setJobTrialLoading] = useState(null);

  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  const handleOnSearch = (event) => {
    setKeyword(event.target.value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchJobTrials(current);
  };

  const handleSelectJobTrial = (JobTrial) => {
    setEditingJobTrial(JobTrial);
  };
  const fetchJobTrials = debounce(async (currentPage) => {
    try {
      setJobTrialLoading(true);
      const response = await jobTrialService.getJobTrials(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setJobTrials(response.data.jobTrials);
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setJobTrialLoading(false);
    }
  }, 500);
  const handleCreateJobTrial = async ({
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  }) => {
    const response = await jobTrialService.createJobTrial(
      company,
      majors,
      englishTitle,
      arabicTitle,
      englishDesc,
      arabicDesc,
      englishDuration,
      arabicDuration,
      englishResponsibilities,
      arabicResponsibilities,
      englishOutcomes,
      arabicOutcomes
    );
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  const handleEditJobTrial = async ({
    id,
    company,
    majors,
    englishTitle,
    arabicTitle,
    englishDesc,
    arabicDesc,
    englishDuration,
    arabicDuration,
    englishResponsibilities,
    arabicResponsibilities,
    englishOutcomes,
    arabicOutcomes,
  }) => {
    const response = await jobTrialService.editJobTrial(
      id,
      company,
      majors,
      englishTitle,
      arabicTitle,
      englishDesc,
      arabicDesc,
      englishDuration,
      arabicDuration,
      englishResponsibilities,
      arabicResponsibilities,
      englishOutcomes,
      arabicOutcomes
    );
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  useEffect(() => {
    fetchJobTrials(pagination.current);

    return () => {
      fetchJobTrials.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    editingJobTrial,
    jobTrialLoading,
    jobTrials,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleCreateJobTrial,
    handleEditJobTrial,
    handleSelectJobTrial,
  };
};
