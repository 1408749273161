import { Form, Input, Modal, notification, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import Button, { BUTTON_TYPE } from "../../../../../common/components/Button";
import PhoneInput, {
  validatePhoneNumber,
} from "../../../../../common/components/InputPhone";

function CompanyModal({ action, open, form, onCancel, onFinish }) {
  return (
    <Modal title={action} open={open} footer={null} onCancel={onCancel}>
      <Form form={form} onFinish={(value) => onFinish(value, action)}>
        <Form.Item
          name="phoneNumber"
          label="Phone number"
          rules={[
            {
              validator: validatePhoneNumber,
              message: "Please input company's phone!",
            },
          ]}
        >
          <PhoneInput form={form} />
        </Form.Item>
        <Form.Item
          name="englishName"
          label="English name"
          rules={[
            {
              required: true,
              message: "Please input company's English name!",
            },
          ]}
        >
          <Input placeholder="input company's English name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input company's email",
            },
          ]}
        >
          <Input placeholder="input your company's email" />
        </Form.Item>

        <Form.Item
          name="arabicName"
          label="Arabic name"
          rules={[
            {
              required: true,
              message: "Please input company's Arabic name!",
            },
          ]}
        >
          <Input placeholder="input company's Arabic name" />
        </Form.Item>

        <Form.Item
          name="englishAddress"
          label="English address"
          rules={[
            {
              required: true,
              message: "Please input company's English address!",
            },
          ]}
        >
          <Input placeholder="input company's English address" />
        </Form.Item>

        <Form.Item
          name="arabicAddress"
          label="Arabic address"
          rules={[
            {
              required: true,
              message: "Please input company's Arabic address!",
            },
          ]}
        >
          <Input placeholder="input company's Arabic address" />
        </Form.Item>

        <Form.Item
          name="website"
          label="Website"
          rules={[
            {
              required: true,
              type: "website",
              message: "Please input company's website",
            },
          ]}
        >
          <Input placeholder="input your company's website" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="profileImage"
          label="Company's logo"
          valuePropName="fileList"
          getValueFromEvent={(e) => e?.fileList}
        >
          <Upload
            name="profileImage"
            accept="image/*"
            listType="picture"
            maxCount={1}
            action={null}
            beforeUpload={(file) => {
              const isLessThan5MB = file.size / 1024 / 1024 < 5; // Convert bytes to MB
              if (!isLessThan5MB) {
                notification["error"]({
                  message: "Action failed!",
                  description: "Image must be smaller than 5MB!",
                });
                return Upload.LIST_IGNORE;
              }
              return false; // Reject file if it exceeds size
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CompanyModal;
