import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useUser } from "../../../contexts/user";
import { sessionService } from "../../../services";
// import { bookedSessionsMapper } from "../common/mapper";
import { userServiceFactory } from "../../../services/student";
import { debounce } from "lodash";
import { bookedSessionsMapper } from "../common/mapper";

export const useBookedSessions = () => {
  /**** STATE ***/
  const { user } = useUser();
  const [needRefresh, setNeedRefresh] = useState(false);
  const [bookedSessions, setBookedSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [dateRange, setDateRange] = useState({
    fromDate: dayjs().startOf("day"),
    endDate: dayjs().startOf("day").add(7, "day"),
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  /** HANDLER */
  const fetchSessions = debounce(async (currentPage) => {
    try {
      setLoading(true);
      const response = await sessionService
        .sessionServiceFactory(user.userType)
        .getAllBookedSessions(
          dateRange.fromDate,
          dateRange.endDate,
          keyword,
          currentPage,
          pagination.pageSize
        );

      if (response) {
        setBookedSessions(bookedSessionsMapper(response.data));
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, 500);

  const handlePagination = async ({ current }) => {
    await fetchSessions(current);
  };
  const handleOnSearch = (event) => {
    const value = event.target.value;
    setKeyword(value);
  };
  const handleEditSessionNotes = async (sessionID, notes) => {
    await sessionService
      .sessionServiceFactory(user.userType)
      .editSessionNote(sessionID, notes);

    setNeedRefresh((prev) => !prev);
  };

  const handleDateRangeChange = (value) => {
    setDateRange({ fromDate: value[0], endDate: value[1] });
  };

  const handleChangeStudentMajors = async (studentID, sessionID, majors) => {
    await userServiceFactory(user.userType).editStudentMajors(
      studentID,
      sessionID,
      majors
    );

    setNeedRefresh((prev) => !prev);
  };

  useEffect(() => {
    fetchSessions(pagination.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefresh, dateRange, keyword]);

  return {
    bookedSessions,
    handleEditSessionNotes,
    handleChangeStudentMajors,
    loading,
    dateRange,
    keyword,
    pagination,
    handleOnSearch,
    handlePagination,
    handleDateRangeChange,
  };
};
