import { TimePicker as TimePickerAntd } from "antd";

function TimePicker({
  value: [startTime, endTime],
  onCalendarChange,
  ...props
}) {
  return (
    <TimePickerAntd.RangePicker
      {...props}
      value={[startTime, endTime]}
      onCalendarChange={onCalendarChange}
      format="HH:mm"
      showTime={{
        format: "HH:mm",
      }}
    />
  );
}

export default TimePicker;
