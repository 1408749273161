import { Form, Input, Modal, Select, Tabs } from "antd";

import Button, { BUTTON_TYPE } from "../../../../../common/components/Button";
import TextArea from "antd/es/input/TextArea";

function ProgramModal({
  action,
  open,
  form,
  majors,
  universities,
  onProgramOptionSearch,
  onCancel,
  onFinish,
}) {
  const majorsOptions = majors.map((x) => ({
    value: x.major,
    label: x.major,
  }));

  const universitiesOptions = universities.map((x) => ({
    value: x.university_name,
    label: x.university_name,
  }));
  const renderMainInfoContent = () => (
    <>
      <Form.Item
        name="major"
        label="Major"
        rules={[
          {
            required: true,
            message: "Please input program's major!",
          },
        ]}
      >
        <Select
          placeholder="Start typing to search company by name/phone"
          showSearch
          optionFilterProp="label"
          notFoundContent={"Company not found"}
          options={majorsOptions}
        />
      </Form.Item>
      <Form.Item
        name="program"
        label="Program"
        rules={[
          {
            required: true,
            message: "Please input program's name!",
          },
        ]}
      >
        <Input placeholder="input program's English name" />
      </Form.Item>
      <Form.Item
        name="university_name"
        label="University Name"
        rules={[
          {
            required: true,
            message: "Please input program's university!",
          },
        ]}
      >
        <Select
          placeholder="Start typing to search university by name"
          showSearch
          filterOption={false}
          options={universitiesOptions}
          onSearch={onProgramOptionSearch}
        />
      </Form.Item>
      {/* Attributes */}
      <Form.Item
        name={["program_attributes", "Study Level"]}
        label="Study level"
        rules={[
          {
            required: true,
            message: "Please input program's study level!",
          },
        ]}
      >
        <Input placeholder="input program's study level" />
      </Form.Item>
      <Form.Item
        name={["program_attributes", "Course Intensity"]}
        label="Course Intensity"
        rules={[
          {
            required: true,
            message: "Please input program's course intensity!",
          },
        ]}
      >
        <Input placeholder="input program's course intensity" />
      </Form.Item>
      <Form.Item
        name={["program_attributes", "Subject"]}
        label="Subject"
        rules={[
          {
            required: true,
            message: "Please input program's subject!",
          },
        ]}
      >
        <Input placeholder="input program's subject" />
      </Form.Item>
      <Form.Item
        name={["program_attributes", "Fee"]}
        label="Fee"
        rules={[
          {
            required: true,
            message: "Please input program's Fee!",
          },
        ]}
      >
        <Input placeholder="input program's Fee" />
      </Form.Item>
      <Form.Item
        name={["program_attributes", "Scholarships"]}
        label="Scholarships"
        rules={[
          {
            required: true,
            message: "Please input program's scholarships!",
          },
        ]}
      >
        <Select
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name={["program_attributes", "Duration"]}
        label="Duration"
        rules={[
          {
            required: true,
            message: "Please input program's duration!",
          },
        ]}
      >
        <Input placeholder="input program's duration" />
      </Form.Item>

      <Form.Item
        name={["program_attributes", "Study Mode"]}
        label="Study Mode"
        rules={[
          {
            required: true,
            message: "Please input program's study mode!",
          },
        ]}
      >
        <Input placeholder="input program's study mode" />
      </Form.Item>
      {/* END */}

      <Form.Item
        name="study_destination"
        label="Study Destination"
        rules={[
          {
            required: true,
            message: "Please input program's study destination!",
          },
        ]}
      >
        <Input placeholder="input program's study destination" />
      </Form.Item>

      <Form.Item
        name="apply_study_level"
        label="Apply Study Level"
        rules={[
          {
            required: true,
            message: "Please input program's apply study level!",
          },
        ]}
      >
        <Input placeholder="input program's apply study level" />
      </Form.Item>

      <Form.Item
        name="overview"
        label="Overview"
        rules={[
          {
            required: true,
            message: "Please input program's overview!",
          },
        ]}
      >
        <TextArea rows={10} />
      </Form.Item>
    </>
  );

  const renderAdditionalInfo = () => {
    return (
      <Form.Item
        name={["additional_info", "Main Subject"]}
        label="Main subject"
      >
        <Input placeholder="input program's main subject" />
      </Form.Item>
    );
  };
  const renderAdmissionRequirements = () => {
    return (
      <>
        <Form.Item name={["admission_requirements", "TOEFL"]} label="TOEFL">
          <Input />
        </Form.Item>

        <Form.Item
          name={["admission_requirements", "PTE Academic"]}
          label="PTE Academic"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["admission_requirements", "Bachelor GPA"]}
          label="Bachelor GPA"
        >
          <Input />
        </Form.Item>

        <Form.Item name={["admission_requirements", "IELTS"]} label="IELTS">
          <Input />
        </Form.Item>

        <Form.Item
          name={["admission_requirements", "Application Deadline"]}
          label="Application Deadline"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["admission_requirements", "Program Duration"]}
          label="Program Duration"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["admission_requirements", "Starting Month(s)"]}
          label="Starting Month(s)"
        >
          <Input />
        </Form.Item>
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: "Main info",
      children: renderMainInfoContent(),
    },
    {
      key: "2",
      label: "Additional info",
      children: renderAdditionalInfo(),
    },
    {
      key: "3",
      label: "Admission requirements",
      children: renderAdmissionRequirements(),
    },
  ];
  return (
    <Modal title={action} open={open} footer={null} onCancel={onCancel}>
      <Form form={form} onFinish={(values) => onFinish(action, values)}>
        <Tabs defaultActiveKey="1" items={items} />

        <Form.Item>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ProgramModal;
