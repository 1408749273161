import { useState } from "react";

import { Flex, Form, notification, Typography } from "antd";

import ContentWrapper from "../ContentWrapper";
import { Button, Icon } from "../../../../common/components";
import { BUTTON_TYPE } from "../../../../common/components/Button";
import { plusIcon } from "../../../../assets/icons";
import { useCompanies } from "../../hooks/useCompanies";
import { companyTableColumns } from "../Table/columns";
import SearchBar from "../../../../common/components/SearchBar";
import CustomTable from "../Table";
import CompanyModal from "./CompanyModal";
import { checkHasChanges } from "../../../../common/utils/common";
import { COMPANY_TABLE_ACTIONS } from "../../../../common/constants/actions";

function CompanyManagement() {
  /** STATE */
  const {
    editingCompany,
    companies,
    companyLoading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleCreateCompany,
    handleEditCompany,
    handleSelectCompany,
  } = useCompanies();
  const [form] = Form.useForm();
  const [modal, setModal] = useState({
    isOpen: false,
    action: null,
  });
  const getFieldsToCompare = () => {
    const oldValuesToCompare = { ...editingCompany };
    delete oldValuesToCompare.id;
    delete oldValuesToCompare.phone;
    delete oldValuesToCompare.countryCode;
    return oldValuesToCompare;
  };
  /** HANDLER */
  const handleOpenCompanyModal = (company, action) => {
    setModal({ isOpen: true, action });
    handleSelectCompany(company);
    switch (action) {
      case COMPANY_TABLE_ACTIONS.CREATE_INFO:
        break;
      case COMPANY_TABLE_ACTIONS.EDIT_INFO:
        form.setFieldsValue({
          ...company,
          profileImage: [
            {
              uid: "-1",
              name: "Uploaded Image",
              status: "done",
              url: company.profileImage, // URL of the uploaded image
            },
          ],
        });
        break;

      default:
        break;
    }
  };
  const handleCloseModal = () => {
    setModal({ isOpen: false });
    form.resetFields();
  };

  const handleOnSubmit = async (newValues) => {
    try {
      switch (modal.action) {
        case COMPANY_TABLE_ACTIONS.CREATE_INFO:
          notification["info"]({
            message: "Processing!",
          });
          await handleCreateCompany(newValues);
          handleCloseModal();
          break;

        case COMPANY_TABLE_ACTIONS.EDIT_INFO:
          if (
            !checkHasChanges(getFieldsToCompare(), {
              ...newValues,
              profileImage: newValues.profileImage[0].url,
            })
          )
            return;
          notification["info"]({
            message: "Processing!",
          });
          await handleEditCompany({ id: editingCompany.id, ...newValues });
          handleCloseModal();
          break;

        default:
          break;
      }
      notification["success"]({
        message: "Success!",
        description: "Performing action successfully",
      });
    } catch (error) {
      notification["error"]({
        message: "Action failed!",
        description: "Issue while performing action",
      });
    }
  };
  return (
    <>
      <ContentWrapper>
        <Flex justify="space-between">
          <Typography.Title className="content__header" level={3}>
            Company
          </Typography.Title>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            icon={<Icon width={16} height={16} src={plusIcon} />}
            onClick={() =>
              handleOpenCompanyModal(null, COMPANY_TABLE_ACTIONS.CREATE_INFO)
            }
          >
            Company
          </Button>
        </Flex>

        <SearchBar
          placeholder="Search by phone number/ name"
          value={keyword}
          onSearch={handleOnSearch}
        />
        <CustomTable
          loading={companyLoading}
          dataSource={companies}
          pagination={pagination}
          columns={companyTableColumns(handleOpenCompanyModal)}
          onPagination={handlePagination}
        />
      </ContentWrapper>

      <CompanyModal
        action={modal.action}
        form={form}
        open={modal.isOpen}
        onCancel={handleCloseModal}
        onFinish={handleOnSubmit}
      />
    </>
  );
}

export default CompanyManagement;
