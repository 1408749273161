import { Modal, Form, Input, InputNumber, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; //

import { BUTTON_TYPE } from "../../../../../common/components/Button";
import { Button } from "../../../../../common/components";
function ProductModal({ open, form, onFinish, onCancel }) {
  const renderModalContent = () => {
    return (
      <Form form={form} onFinish={(value) => onFinish(value)}>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input product's title!",
            },
          ]}
        >
          <Input placeholder="input product's title" />
        </Form.Item>

        <Form.Item
          name="priceInJordan"
          label="Price in Jordan"
          rules={[
            {
              required: true,
              message: "Please input price in jordan",
            },
          ]}
        >
          <InputNumber placeholder="input price in jordan" />
        </Form.Item>

        <Form.Item
          name="priceInUSD"
          label="Price in USD"
          rules={[
            {
              required: true,
              message: "Please input price in USD",
            },
          ]}
        >
          <InputNumber placeholder="input price in USD" />
        </Form.Item>

        <Form.Item
          name="isJobTrial"
          label="Is job trial"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { value: true, label: "true" },
              { value: false, label: "false" },
            ]}
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="totalSessions"
          label="Total sessions"
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          name="totalJobTrialExps"
          label="Total job trial exps"
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          name="features"
          label="Features"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactQuill theme="snow" placeholder="input product's features" />
        </Form.Item>

        <Form.Item>
          <Button
            type={BUTTON_TYPE.PRIMARY}
            style={{ float: "inline-end" }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal title="Update product" open={open} footer={null} onCancel={onCancel}>
      {renderModalContent()}
    </Modal>
  );
}

export default ProductModal;
