import { useState } from "react";

import { Flex, notification } from "antd";
import { circlePlusIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";

import TimePicker from "../../TimePicker";
import "./index.css";
function SessionCreator({ selectedDate, onAddSession }) {
  const [timeValue, setTimeValue] = useState([]);

  const handleCalendarChange = (val) => {
    if (val && val[0]) {
      const startTime = val[0];
      const endTime = startTime.add(50, "minute");

      if (endTime.isAfter(startTime) && val[0].isSame(endTime, "day")) {
        setTimeValue([startTime, endTime]);
      } else {
        notification["error"]({
          message: "Error!",
          description: "End time must be greater than start time",
        });
      }
    }
  };
  return (
    <Flex className="session__creator__wrapper" gap={8} align="center">
      <TimePicker
        value={timeValue}
        onCalendarChange={handleCalendarChange}
        disabled={[false, true]}
        defaultPickerValue={[selectedDate, selectedDate]}
      />

      <div className="session__creator__icon">
        <Icon
          clickable
          width={20}
          height={20}
          src={circlePlusIcon}
          onClick={() => onAddSession(timeValue)}
        />
      </div>
    </Flex>
  );
}

export default SessionCreator;
