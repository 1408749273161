import { Space, Typography } from "antd";

import { editIcon, viewIcon } from "../../../../../../assets/icons";
import { Icon } from "../../../../../../common/components";
import { JOB_TRIAL_TABLE_ACTIONS } from "../../../../../../common/constants/actions";

export const jobTrialTableColumns = (onOpenModal) => [
  {
    title: "Job English title",
    key: "englishTitle",
    dataIndex: "englishTitle",
  },
  {
    title: "Job Arabic title",
    dataIndex: "arabicTitle",
    key: "arabicTitle",
  },
  {
    title: "Action",
    key: "action",
    render: (_, jobTrial) => {
      return (
        <Space>
          <Icon
            width={25}
            height={25}
            className="logo__action"
            preview={false}
            src={viewIcon}
            onClick={() =>
              onOpenModal(jobTrial, JOB_TRIAL_TABLE_ACTIONS.VIEW_INFO)
            }
          />
          <Icon
            className="logo__action"
            preview={false}
            src={editIcon}
            onClick={() =>
              onOpenModal(jobTrial, JOB_TRIAL_TABLE_ACTIONS.EDIT_INFO)
            }
          />
        </Space>
      );
    },
  },
];

export const jobApplicationTableColumns = () => [
  {
    title: "Company",
    key: "company",
    dataIndex: "company",
    render: (_, { jobTrial }) => {
      return <Typography.Text>{jobTrial.company.englishName}</Typography.Text>;
    },
  },
  {
    title: "Job English title",
    dataIndex: "englishTitle",
    key: "englishTitle",
    render: (_, { jobTrial }) => {
      return <Typography.Text>{jobTrial.englishTitle}</Typography.Text>;
    },
  },
  {
    title: "Student",
    dataIndex: "student",
    key: "student",
    render: (_, { student }) => {
      return (
        <Typography.Text>
          {student.firstName} {student.lastName}
        </Typography.Text>
      );
    },
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    key: "Created At",
    render: (_, record) => {
      return <Typography.Text>{record.createdAt}</Typography.Text>;
    },
  },
];
