import { Badge, Drawer, Flex, Typography } from "antd";
import moment from "moment";

import { rejectIcon } from "../../../../../assets/icons";
import { Button, Icon } from "../../../../../common/components";
import { BUTTON_TYPE } from "../../../../../common/components/Button";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import SessionCreator from "./SessionCreator";

import SessionTimeInfo from "../../../../../common/components/SessionTimeInfo";
import "./index.css";
function TimeSlotsDrawer({
  title,
  open,
  sessions,
  selectedDate,
  onClose,
  onAddSession,
  onRemoveSession,
  onSubmit,
}) {
  const renderSessions = () => {
    if (!sessions) return; //Hide deleted and booked session from drawer
    const filteredSessions = sessions.filter(
      (x) => !x.isBooked && !x.isDeleted
    );

    const showedSessions = filteredSessions.map((x) => {
      return (
        <Flex key={x.startDate} gap={8} align="center">
          <TimePicker
            value={[moment(x.startDate), moment(x.endDate)]}
            disabled={[true, true]}
            className="available__time__slot__time__picker"
          />
          <div>
            <Icon
              clickable
              width={20}
              height={20}
              src={rejectIcon}
              onClick={() => onRemoveSession(x)}
            />
          </div>
        </Flex>
      );
    });

    return showedSessions;
  };
  const hasSessionsToUpdate = sessions?.some((x) => x.isModified);
  return (
    <Drawer
      closable
      destroyOnClose
      title={title}
      placement="right"
      open={open}
      onClose={onClose}
    >
      <SessionTimeInfo />
      <DatePicker
        value={selectedDate}
        disabled
        className="available__time__slot__date__picker"
      />
      {renderSessions()}

      <SessionCreator selectedDate={selectedDate} onAddSession={onAddSession} />

      <Flex vertical justify="space-evenly">
        {hasSessionsToUpdate && (
          <>
            <Flex gap={5} align="center">
              <Badge status="warning" />
              <Typography.Text disabled>
                You have unsaved time slot(s)
              </Typography.Text>
            </Flex>
            <Button type={BUTTON_TYPE.PRIMARY} onClick={onSubmit}>
              Save
            </Button>
          </>
        )}
      </Flex>
    </Drawer>
  );
}

export default TimeSlotsDrawer;
