import { useEffect, useState } from "react";

import { debounce } from "lodash";

import { advisorService } from "../../../services";
export const useAdvisors = () => {
  //**** STATE ***//
  const [advisors, setAdvisors] = useState([]);
  const [advisorLoading, setAdvisorLoading] = useState(false);
  const [editingAdvisor, setEditingAdvisor] = useState(null);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [keyword, setKeyword] = useState(null);

  const handleOnSearch = (event) => {
    setKeyword(event.target.value);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handlePagination = async ({ current }) => {
    await fetchAdvisors(current);
  };

  const handleSelectAdvisor = (advisor) => {
    setEditingAdvisor(advisor);
  };
  const fetchAdvisors = debounce(async (currentPage) => {
    try {
      setAdvisorLoading(true);
      const response = await advisorService.getAdvisors(
        keyword,
        currentPage,
        pagination.pageSize
      );
      if (response) {
        setAdvisors(response.data.advisors);
        setPagination((prev) => ({
          ...prev,
          current: currentPage,
          total: response.data.total,
        }));
      }
    } catch (error) {
    } finally {
      setAdvisorLoading(false);
    }
  }, 500);
  const handleCreateAdvisor = async ({
    email,
    englishName,
    englishBio,
    arabicName,
    arabicBio,
    sessionQuota,
  }) => {
    const response = await advisorService.createAdvisor(
      email,
      englishName,
      englishBio,
      arabicName,
      arabicBio,
      sessionQuota
    );
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  const handleEditAdvisor = async ({
    id,
    email,
    englishName,
    englishBio,
    arabicName,
    arabicBio,
    sessionQuota,
  }) => {
    const response = await advisorService.editAdvisor(
      id,
      email,
      englishName,
      englishBio,
      arabicName,
      arabicBio,
      sessionQuota
    );
    if (response) {
      setNeedRefresh((prev) => !prev);
    }
  };
  useEffect(() => {
    fetchAdvisors(pagination.current);

    return () => {
      fetchAdvisors.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, needRefresh]);

  return {
    editingAdvisor,
    advisors,
    advisorLoading,
    pagination,
    keyword,
    handleOnSearch,
    handlePagination,
    handleCreateAdvisor,
    handleEditAdvisor,
    handleSelectAdvisor,
  };
};
