import { useEffect, useState } from "react";

import { companyService } from "../../../services";
import { companiesTableDataSourceMapper } from "../common/mapper";

export const useAllCompanies = () => {
  //**** STATE ***//
  const [companies, setCompanies] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);

  const fetchCompanies = async () => {
    try {
      setCompanyLoading(true);
      const response = await companyService.getCompanies(null, null, null); //get all
      if (response) {
        setCompanies(companiesTableDataSourceMapper(response.data.companies));
      }
    } catch (error) {
    } finally {
      setCompanyLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return {
    companies,
    companyLoading,
  };
};
