import {
  formatToDateInUtc,
  formatToSessionUtc,
  getTimeRange,
} from "../../common/utils/date";
import { USER_TYPE } from "../../contexts/user";
import axiosClient from "../base";

export const sessionServiceFactory = (userType) => {
  if (userType === USER_TYPE.ADMIN)
    return {
      getAllBookedSessions,
      editSessionNote: editSessionNoteForAdmin,
    };
  if (userType === USER_TYPE.ADVISOR)
    return {
      getAllBookedSessions: getAllBookedSessionsByAdvisor,
      editSessionNote,
    };
};

export const addSessionsForAdvisor = (newSessions) => {
  const sessions = newSessions.map((x) => ({
    ...x,
    startDate: formatToSessionUtc(x.startDate),
  }));
  return axiosClient.post("sessions", {
    sessions,
  });
};

export const getSessions = ({ year, month }) => {
  const [fromDate, endDate] = getTimeRange(year, month);
  return axiosClient.get("sessions", {
    params: {
      fromDate,
      endDate,
    },
  });
};

export const getAllBookedSessions = (fromDate, endDate, keyword) => {
  const formattedFromDate = formatToDateInUtc(fromDate);
  const formattedEndDate = formatToDateInUtc(endDate);
  return axiosClient.get("sessions/booked", {
    params: {
      keyword,
      fromDate: formattedFromDate,
      endDate: formattedEndDate,
    },
  });
};

export const getAllBookedSessionsByAdvisor = (fromDate, endDate, _) => {
  const formattedFromDate = formatToDateInUtc(fromDate);
  const formattedEndDate = formatToDateInUtc(endDate);
  return axiosClient.get("sessions/booked/owned", {
    params: {
      fromDate: formattedFromDate,
      endDate: formattedEndDate,
    },
  });
};

export const getSessionsByAdvisor = (advisorID, { year, month }) => {
  const [fromDate, endDate] = getTimeRange(year, month);
  return axiosClient.get(`sessions/advisors/${advisorID}`, {
    params: {
      fromDate,
      endDate,
    },
  });
};

export const editSessionNote = (sessionID, notes) => {
  return axiosClient.put(`sessions/${sessionID}/advisor`, {
    notes,
  });
};

export const editSessionNoteForAdmin = (sessionID, notes) => {
  return axiosClient.put(`sessions/${sessionID}/admin`, {
    notes,
  });
};
